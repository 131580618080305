import React from "react";
import Text from "../../components/data-display/text";
import InlineLink from "../../components/data-display/inline-link";

export default function QuikTasksection(classes) {
  return (
    <div style={{padding: "10vh 16px"}}>
      <Text variant={"body1"} medium className={classes.subHeading}>
        Effective: 15th January 2024
      </Text>
      <div style={{height: "24px"}} />
      <Text variant={"h6"} bold className={classes.subHeading}>
              1. Introduction
          </Text>
      <Text variant={"body1"} regular>
        QuickTask (“we,” “us,” or “our”) is committed to safeguarding the privacy and security of your personal information. This GDPR Privacy Policy outlines how we collect, use, and protect your data in compliance with the General Data Protection Regulation (GDPR).
      </Text>
      <div style={{height: "24px"}} />

      <Text variant={"h6"} bold className={classes.subHeading}>
      QuickTask Effective Date
      </Text>
      <Text variant={"body1"} regular>
    Thank you for selecting QuickTask! This Privacy Policy elucidates the comprehensive practices and principles that govern the collection, utilisation, safeguarding, and sharing of your personal information when you engage with the QuickTask platform, website, and related services. Your privacy is paramount to us, and we are dedicated to ensuring the confidentiality, security, and responsible handling of your data
      </Text>
      <div style={{height: "24px"}} />

      <Text variant={"h6"} bold className={classes.subHeading}>
        1. Information We Collect
      </Text>
      <Text variant={"body1"} regular>
        <ul>
          <ul>
            <li>a. Personal Information:</li>
            <li>
               Upon registration on QuickTask, we collect personal information such as your name,
              email address, and contact details to establish and manage your account effectively.
            </li>
          </ul>
          <ul>
            <li>b. Task-related Information:</li>
            <li>
              Information related to tasks, including task descriptions, locations, compensation
              details, and other task-specific data.{" "}
            </li>
          </ul>{" "}
          <ul>
            <li>c. Transaction Information:</li>
            <li>
              ● Details of transactions conducted through the QuickTask platform, ensuring secure
              and transparent financial interactions.{" "}
            </li>
          </ul>{" "}
          <ul>
            <li>d. User Communication:</li>
            <li>
              ● Correspondence between users, including communications with QuickTask support to
              address queries and provide assistance.
            </li>
          </ul>{" "}
        </ul>
      </Text>
      <div style={{height: "24px"}} />
      <Text variant={"h6"} bold className={classes.subHeading}>
        3. Information Sharing
      </Text>
      <Text variant={"body1"} regular>
        <ul>
          <ul>
            <li>a. Account Information:</li>
            <li>
              ● Task posters and performers may share personal information, including contact
              details and task-related specifics, to facilitate effective communication and
              successful task completion.{" "}
            </li>
          </ul>
          <ul>
            <li>b. Legal Compliance:</li>
            <li>
              ● Adhere to legal obligations and respond to legal requests, ensuring compliance with
              applicable laws and regulations.
            </li>
          </ul>{" "}
          <ul>
            <li>c. Service Providers:</li>
            <li>
              ● Engage with third-party service providers, subject to confidentiality agreements, to
              facilitate and enhance service provision within the QuickTask platform.{" "}
            </li>
          </ul>{" "}
        </ul>
      </Text>
      <div style={{height: "24px"}} />
      <Text variant={"h6"} bold className={classes.subHeading}>
        4. Your Choices
      </Text>
      <Text variant={"body1"} regular>
        <ul>
          <ul>
            <li>a. Account Information:</li>
            <li>
              ● Empower users to review, update, and manage their account information through the
              QuickTask platform, ensuring personalization and control.{" "}
            </li>
          </ul>
          <ul>
            <li>b. Communication Preferences:</li>
            <li>
              ● Provide users with the flexibility to choose their communication preferences,
              including the option to opt in or opt out of promotional emails, thereby ensuring a
              tailored user experience.{" "}
            </li>
          </ul>{" "}
        </ul>
      </Text>
      <div style={{height: "24px"}} />
      <Text variant={"h6"} bold className={classes.subHeading}>
        5. Security Measures
      </Text>
      <Text variant={"body1"} regular>
        <ul>
          <ul>
            <li>a. Data Security:</li>
            <li>
              ● Implement robust security measures to protect personal information from unauthorised
              access, ensuring the highest standards of data security.
            </li>
          </ul>
          <ul>
            <li>b. SSL Encryption:</li>
            <li>
              ● Employ Secure Socket Layer (SSL) encryption to guarantee secure and encrypted data
              transmission, enhancing overall data protection
            </li>
          </ul>{" "}
        </ul>
      </Text>
      <div style={{height: "24px"}} />
      <Text variant={"h6"} bold className={classes.subHeading}>
        6. Cookies and Tracking Technologies
      </Text>
      <Text variant={"body1"} regular>
        <ul>
          <ul>
            <li>a. Cookies:</li>
            <li>
              ● Leverage cookies to enhance the user experience, gather insights on site usage, and
              customise interactions based on user preferences, fostering an intuitive and
              personalised platform.
            </li>
          </ul>
          <ul>
            <li>b. Opt-out:</li>
            <li>
              ● Provide users with the option to adjust browser settings to opt out of cookies or
              receive notifications when cookies are being utilised, empowering users to control
              their privacy preferences.
            </li>
          </ul>{" "}
        </ul>
      </Text>

      <div style={{height: "48px"}} />
      <Text variant={"h6"} bold className={classes.subHeading}>
        7. Children’s Privacy
      </Text>
      <Text variant={"body1"} regular>
        a. Age Restrictions: <br />
        <br />
        Clarify that QuickTask is intended for users over the age of 16, and we do not knowingly
        collect information from individuals under 16, ensuring compliance with privacy regulations{" "}
        <br />
        <InlineLink
          to={
            "https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
          }
        >
          https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage
        </InlineLink>
      </Text>

      <div style={{height: "48px"}} />
      <Text variant={"h6"} bold className={classes.subHeading}>
        8. Changes to the Privacy Policy
      </Text>
      <Text variant={"body1"} regular>
        a. Updates:
        <ul>
          <li>
            Acknowledge that the Privacy Policy may be subject to updates, and users will be duly
            notified of any material changes, ensuring transparency and awareness.
          </li>
        </ul>
      </Text>

      <div style={{height: "48px"}} />
      <Text variant={"h6"} bold className={classes.subHeading}>
        9. Contact Information
      </Text>
      <Text variant={"body1"} regular>
        If you have inquiries or concerns regarding this Privacy Policy, please do not hesitate to
        contact us If you have inquiries or concerns regarding this Privacy Policy, please do not
        hesitate to contact us
        <br />
        at [MAILTO:privacy@quicktask.co.uk].
        <InlineLink to={"MAILTO:privacy@quicktask.co.uk"}>MAILTO:privacy@quicktask.co.uk</InlineLink>
        We value your feedback and are committed to addressing any concerns promptly. By engaging
        with QuickTask, you explicitly agree to adhere to the terms outlined in this Privacy Policy.
        We appreciate your trust in QuickTask and strive to provide a secure and user-friendly
        platform.
      </Text>
    </div>
  );
}
